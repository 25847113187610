import { Button } from '@mui/material';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { BRAND_NAME } from '../../../../shared/env';
import {
  useAutoSignIn,
  useUserState,
} from '../../../../shared/hooks/accountHook';
import { useUnreadNotification } from '../../../../shared/hooks/notificationHook';
import {
  useSignInRedirect,
  useSignOut,
} from '../../../../shared/hooks/oauthHook';
import menuIcon from '../../assets/icons/icon_menu.svg';
import notiIcon from '../../assets/icons/icon_noti.svg';
import { ColoredIcon } from '../../common/components/Icon';
import { LanguageSelector } from '../../common/components/LanguageSelector';
import { LogoTitle } from '../../common/components/LogoTitle';
import {
  WindowSize,
  WindowSizeContext,
  useWindowSize,
} from '../../hooks/windowSizeHook';

import { MenuDrawer } from './MenuDrawer';
import {
  LayoutHeaderContainer,
  LayoutHeaderContent,
  LayoutHeaderContentLeft,
  LayoutHeaderContentRight,
  AlertNoti,
  LayoutHeaderLink,
  LayoutHeaderButton,
} from './components';

const NoUnderlineLink = styled(Link)`
  text-decoration: none;
  width: max-content;
`;

interface LayoutHeaderContextType {
  showLoginButton: boolean;
  onClickSignUpButton?(): void;
  onClickLoginButton?(): void;
  onClickLogoutButton?(): void;
}

const defaultLayoutHeaderContext: LayoutHeaderContextType = {
  showLoginButton: true,
  onClickSignUpButton: undefined,
  onClickLoginButton: undefined,
  onClickLogoutButton: undefined,
};

const LayoutHeaderContext = createContext<LayoutHeaderContextType>(
  defaultLayoutHeaderContext,
);

export const useLayoutHeaderContext = (): Required<LayoutHeaderContextType> => {
  const navigate = useNavigate();
  const { mutateAsync: signOut } = useSignOut();

  const signInRedirect = useSignInRedirect();
  const defaultOnClickSignUpButton = () => {
    signInRedirect({ register: true });
  };
  const defaultOnClickLoginButton = () => {
    signInRedirect({});
  };
  const defaultOnClickLogoutButton = async () => {
    await signOut();
    navigate('/');
  };

  const {
    onClickLoginButton = defaultOnClickLoginButton,
    onClickSignUpButton = defaultOnClickSignUpButton,
    onClickLogoutButton = defaultOnClickLogoutButton,
    ...rest
  } = useContext(LayoutHeaderContext);

  return {
    onClickLoginButton,
    onClickSignUpButton,
    onClickLogoutButton,
    ...rest,
  };
};

export const LayoutHeaderContextProvider = ({
  children,
  ...value
}: Partial<LayoutHeaderContextType> & PropsWithChildren) => {
  return (
    <LayoutHeaderContext.Provider
      value={{
        ...defaultLayoutHeaderContext,
        ...value,
      }}>
      {children}
    </LayoutHeaderContext.Provider>
  );
};

const Links = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  const { isLoading } = useAutoSignIn();
  const [user] = useUserState();

  const {
    showLoginButton = true,
    onClickSignUpButton,
    onClickLoginButton,
    onClickLogoutButton,
  } = useLayoutHeaderContext();

  const isLoggedIn = user !== undefined;
  const isNormal = useWindowSize() === WindowSize.NORMAL;

  return (
    <>
      <NoUnderlineLink to="/images-list">
        <LayoutHeaderLink>{t('headers.links.serviceLink')}</LayoutHeaderLink>
      </NoUnderlineLink>
      <NoUnderlineLink to="/about">
        <LayoutHeaderLink>
          {t('headers.links.aboutLink', { brandName: BRAND_NAME })}
        </LayoutHeaderLink>
      </NoUnderlineLink>
      {isNormal && (
        <LayoutHeaderLink
          onClick={() => {
            ChannelIO('showMessenger');
          }}>
          {t('headers.links.centerLink')}
        </LayoutHeaderLink>
      )}
      <LanguageSelector />
      {isLoggedIn && !isLoading ? (
        <LayoutHeaderButton onClick={onClickLogoutButton}>
          {t('headers.links.logoutButton')}
        </LayoutHeaderButton>
      ) : (
        <>
          {showLoginButton && (
            <LayoutHeaderLink onClick={onClickLoginButton}>
              {t('headers.links.loginButton')}
            </LayoutHeaderLink>
          )}
          <LayoutHeaderButton onClick={onClickSignUpButton}>
            {t('headers.links.signupButton')}
          </LayoutHeaderButton>
        </>
      )}
    </>
  );
};

const CheckNotification = () => {
  const hasNoti = useUnreadNotification();

  return <>{hasNoti && <AlertNoti />}</>;
};

export const PreviewHeaderLayout = () => {
  const windowSize = useContext(WindowSizeContext);
  const isMobile = windowSize === WindowSize.MOBILE;

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <LayoutHeaderContainer $height={isMobile ? '56px' : '114px'}>
        <LayoutHeaderContent>
          <LayoutHeaderContentLeft>
            <LogoTitle />
          </LayoutHeaderContentLeft>
          {isMobile ? (
            <LayoutHeaderContentRight $gap="28px">
              <LanguageSelector icon />
              <Button
                sx={{
                  padding: 0,
                  minWidth: '20px',
                }}
                onClick={() => setOpenMenu(true)}>
                <ColoredIcon
                  width="20px"
                  height="20px"
                  imageUrl={menuIcon}
                  imageWidth="20px"
                  color="white"
                />
              </Button>
            </LayoutHeaderContentRight>
          ) : (
            <LayoutHeaderContentRight $gap="34px">
              <Links />
            </LayoutHeaderContentRight>
          )}
        </LayoutHeaderContent>
      </LayoutHeaderContainer>
      <MenuDrawer
        open={openMenu}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
      />
    </>
  );
};

export const LandingLayoutHeader = () => {
  const { isLoading } = useAutoSignIn();
  const navigate = useNavigate();
  const windowSize = useContext(WindowSizeContext);
  const isMobile = windowSize === WindowSize.MOBILE;
  const isShrink = windowSize === WindowSize.SHRINK_MENU;

  const [openMenu, setOpenMenu] = useState(false);

  const [user] = useUserState();
  const isLoggedIn = user !== undefined;

  return (
    <>
      <LayoutHeaderContainer $height={isMobile ? '56px' : '114px'}>
        <LayoutHeaderContent>
          <LayoutHeaderContentLeft>
            <LogoTitle />
          </LayoutHeaderContentLeft>
          {isMobile ? (
            <LayoutHeaderContentRight $gap="28px">
              <LanguageSelector icon />
              {isLoggedIn && !isLoading && (
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '20px',
                  }}
                  onClick={() => navigate('/notifications')}>
                  <CheckNotification />
                  <ColoredIcon
                    width="20px"
                    height="20px"
                    imageUrl={notiIcon}
                    imageWidth="18px"
                    color="white"
                  />
                </Button>
              )}
              <Button
                sx={{
                  padding: 0,
                  minWidth: '20px',
                }}
                onClick={() => setOpenMenu(true)}>
                <ColoredIcon
                  width="20px"
                  height="20px"
                  imageUrl={menuIcon}
                  imageWidth="20px"
                  color="white"
                />
              </Button>
            </LayoutHeaderContentRight>
          ) : (
            <LayoutHeaderContentRight $gap={isShrink ? '28px' : '34px'}>
              <Links />
            </LayoutHeaderContentRight>
          )}
        </LayoutHeaderContent>
      </LayoutHeaderContainer>
      <MenuDrawer
        open={openMenu}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
      />
    </>
  );
};

export const LayoutHeader = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  const [user] = useUserState();
  const username = t('headers.name', { username: user?.name });
  const hasNoti = useUnreadNotification();
  const navigate = useNavigate();
  const windowSize = useContext(WindowSizeContext);
  const isMobile = windowSize === WindowSize.MOBILE;

  const { mutate: signOut } = useSignOut();

  const onClickLogoutButton = () => {
    signOut();
    navigate('/');
  };

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <LayoutHeaderContainer $height={isMobile ? '56px' : '80px'}>
        <LayoutHeaderContent>
          <LayoutHeaderContentLeft>
            <LogoTitle />
          </LayoutHeaderContentLeft>
          <LayoutHeaderContentRight $gap={isMobile ? '28px' : '18px'}>
            {!isMobile && username}
            <Button
              sx={{
                padding: 0,
                minWidth: '20px',
              }}
              onClick={() => navigate('/notifications')}>
              {hasNoti && <AlertNoti />}
              <ColoredIcon
                width="20px"
                height="20px"
                imageUrl={notiIcon}
                imageWidth="18px"
                color="white"
              />
            </Button>
            {isMobile ? (
              <>
                <LanguageSelector icon />
                <Button
                  sx={{
                    padding: 0,
                    minWidth: '20px',
                  }}
                  onClick={() => setOpenMenu(true)}>
                  <ColoredIcon
                    width="20px"
                    height="20px"
                    imageUrl={menuIcon}
                    imageWidth="20px"
                    color="white"
                  />
                </Button>
              </>
            ) : (
              <>
                <LanguageSelector />
                <LayoutHeaderButton onClick={onClickLogoutButton}>
                  {t('headers.links.logoutButton')}
                </LayoutHeaderButton>
              </>
            )}
          </LayoutHeaderContentRight>
        </LayoutHeaderContent>
      </LayoutHeaderContainer>
      <MenuDrawer
        open={openMenu}
        onOpen={() => setOpenMenu(true)}
        onClose={() => setOpenMenu(false)}
      />
    </>
  );
};
